// ======
// Colors
// ======

$black: #231F20;

$white: #FDFCFC;

// Neutrals

$grey800: #443C3E;
$grey700: #5F5457;
$grey600: #87787C;
$grey500: #9F9396;
$grey400: #B7AEB0;
$grey300: #CFC9CA;
$grey200: #EEECED;

// Primary

$primary900: #05072E;
$primary800: #101589;
$primary700: #151CB7;
$primary600: #1B23E4;
$primary500: #484FEA;
$primary400: #767BEF;
$primary300: #A4A7F4;
$primary200: #D1D3FA;
$primary100: #F1F2FD;

// Secondary

$secondary900: #59470D;
$secondary800: #7D6312;
$secondary700: #9C7C16;
$secondary600: #B7911A;
$secondary500: #E0B428;
$secondary400: #E9C963;
$secondary300: #EFD890;
$secondary200: #F5E8BC;
$secondary100: #FCF7E9;


// ==========
// Font Sizes
// ==========

$fontSize1: .75rem; // 12px
$fontSize2: .875rem; // 14px
$fontSize3: 1rem; // 16px
$fontSize4: 1.125rem; // 18px
$fontSize5: 1.25rem; // 20px
$fontSize6: 1.5rem; // 24px
$fontSize7: 1.875rem; // 30px
$fontSize8: 2.25rem; // 36px
$fontSize9: 3rem; // 48px
$fontSize10: 3.75rem; // 60px
$fontSize11: 4.5rem; // 72px

// ==============
// Spacing/Sizing
// ==============

$size1: 4px;
$size2: 8px;
$size3: 12px;
$size4: 16px;
$size5: 24px;
$size6: 32px;
$size7: 48px;
$size8: 64px;
$size9: 96px;
$size10: 128px;
$size11: 192px;
$size12: 256px;
$size13: 384px;
$size14: 512px;
$size15: 640px;
$size16: 768px;

// =====
// Misc.
// =====

$transition: .2s;