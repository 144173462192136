@import "variables.scss";

// ==============
// === GLobal ===
// ==============

* ,*:before, *:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: $black;
}

body {
    background-color: $white;
    padding-top: $size8;
    height: 100%;
    // position: relative;
}

ul, ol, li {
    list-style: none;
}

h2 {
    font-size: $fontSize9;
    text-align: right;
    // margin-top: $size11;
    margin-bottom: $size10;
}

h3 {
    font-size: $fontSize7;
    margin-bottom: $size6;
}

p {
    line-height: 1.5;
}

a {
    color: inherit;
    text-decoration: inherit;
}

img {
    max-width: 100%;
    display: block;
}

.primary-btn {
    display: inline-block;
    padding: $size3 $size5;
    background-color: $primary500;
    color: $primary100;
    border: 2px solid $primary500;
    border-radius: 10px;
    margin-left: $size5;
    transition: all $transition;

    &:hover {
        background-color: $primary600;
    }
}

.secondary-btn {
    display: inline-block;
    padding: $size3 $size5;
    border: 2px solid $grey200;
    color: $primary500;
    border-radius: 10px;
    margin-left: $size5;
    transition: all $transition;

    &:hover {
        border-color: $primary500;
    }
}

.underline {
    border-bottom: 1px solid $white;
    opacity: .5;
    margin: $size5 0;
}

@keyframes twinkle1 {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

@keyframes twinkle2 {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: .2;
    }
}

@keyframes twinkle3 {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: .4;
    }
}

@keyframes twinkle4 {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: .6;
    }
}

@keyframes twinkle5 {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: .8;
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {

}

// For mobile screens
@media (max-width: 480px) {
    h2 {
        font-size: $fontSize7;
        margin-bottom: $size7;
    }

    h3 {
        font-size: $fontSize5;
    }
}

// ===========
// === App ===
// ===========

.App {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

// ======================
// === Section Border ===
// ======================

.SectionBorder {
    width: 100%;
    margin-bottom: -5px;
    // transform: translateY(30px);
}

// ==============
// === Navbar ===
// ==============

.Navbar {
    width: 100vw;
    height: $size9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: $white;
    background-color: $black;
    // background-color: rgba(35, 31, 32, .9);
    position: fixed;
    top: 0;
    padding: $size2 $size8;
    z-index: 5;

        a {
            cursor: pointer;
            color: $white;
            display: flex;
            align-items: center;

            img {
                width: $size8;
                height: auto;
                margin-right: $size3;
            }

            div {
                color: $white;
                
                p {
                    color: $white;
                    font-size: $fontSize4;
                }
            }
        }

    ul {
        display: flex;

        li {
            // cursor: pointer;

            &:not(:last-child) {
                margin-right: $size5;
            }
            a {
                color: $white;
                display: inline-block;
                border-bottom: 2px solid $black;
                transition: all $transition;
                
                &:hover, &.active {
                    border-color: $secondary500;
                }

            }
        }
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {
    .Navbar {

        ul {
            display: none;
        }
    }
}

// For mobile screens
@media (max-width: 480px) {
    .Navbar {
        padding-left: $size5;
        padding-right: $size5;
        height: $size8;

        a {

            img {
                width: $size7;
            }

            div {

                p {
                    font-size: $fontSize2;
                }
            }
        }

        // ul {
        //     display: none;
        // }
    }
}

// =================
// === Hamburger ===
// =================

.Hamburger {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    z-index: 6;

    .line {
        width: 100%;
        height: 3px;
        background-color: $white;
        border-radius: 15px;
    }

    #middle {
        transition-delay: .2s;

    }

    .hide {
        opacity: 0;
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {
    .Hamburger {
        display: flex;
   }
}

// For mobile screens
@media (max-width: 480px) {
   
}

// ===================
// === Mobile Menu ===
// ===================

.MobileMenu {
    width: 80vw;
    height: 100vh;
    z-index: 3;
    position: fixed;
    // position: absolute;
    // position: static;
    top: 0;
    // right: 0;
    // bottom: 0;
    right: 0;
    background-color: $black;
    // background-color: rgba(35, 31, 32, .95);
    padding: $size8;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        li {
            max-width: 100%;
            margin: $size4 0;

            a {
                color: $white;
                font-size: $fontSize6;
                border-bottom: 2px solid $black;
                transition: .2s;

                &:hover, &.active {
                    border-color: $secondary500;
                }
            }
        }
    }

    .underline {
        border-color: $white;
        width: 100%;
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {
    .MobileMenu {
        display: flex;
    }
}

// For mobile screens
@media (max-width: 480px) {
    
}

// ============
// === Hero ===
// ============

.Hero {
    padding: $size8 $size8 $size9;
    // margin-bottom: $size9;
    min-height: calc(100vh - 64px);
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // background-image: url("../images/hero-background.svg");
    // background-position: bottom;
    // background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background-color: $secondary100;
    background-color: $white;

    div {
        width: 55vw;

        p {
            font-size: $fontSize6;
            margin-bottom: $size6;
        }
    
        h1 {
            font-size: $fontSize11;
            margin-bottom: $size6;
    
            .blue-text {
                color: $primary600;
                font-size: $fontSize11;
            }
        }
    
        a {
            display: inline-block;
            font-size: $fontSize6;
            // border-bottom: 2px solid $black;
            // padding-bottom: $size1;
            margin-left: 0;
            transition: all $transition;
    
            // &:hover {
            //     border-color: $secondary500;
            //     transform: translateY(-3px);
            // }
        }
    }

    img {
        max-width: 40vw;
    }
}

// For medium-large screens
@media (max-width: 1200px) {
    .Hero {

        div {

            h1 {
                // font-size: $fontSize10;
                font-size: 5.5vw;
        
                .blue-text {
                    // font-size: $fontSize10;
                    font-size: 5.5vw;
                }
            }

            p {
                font-size: 2vw;
            }

            a {
                font-size: 2vw;
            }
        }
    }
}

// For tablets
@media (max-width: 768px) {
    .Hero {
        flex-direction: column-reverse;
        padding-top: 0;
        justify-content: flex-end;

        div {
            text-align: center;

            h1 {
                // font-size: 6.5vw;
                font-size: 8vw;
    
                .blue-text {
                    // font-size: $fontSize10;
                    // font-size: 6.5vw;
                    font-size: 8vw;
                }
            }

            p {
                font-size: 3.3vw;
            }

            a {
                font-size: 3.3vw;
            }
        }

        img {
            max-height: 35vh;
            max-width: 100vw;
            margin-bottom: $size8;
        }
    }
}

// For mobile screens
@media (max-width: 480px) {
    .Hero {
        // padding-top: $size9;
        padding-left: $size5;
        padding-right: $size5;
        // display: flex;
        // flex-direction: column;
        // justify-content: center;
        // min-height: 100%;
        min-height: calc(100vh - 64px);
        
        p {
            // font-size: $fontSize4;
            // margin-bottom: $size7;
        }

        h1 {
            // font-size: $fontSize8;
            // margin-bottom: $size7;

            .blue-text {
                // font-size: $fontSize8;
            }
        }

        a {
            // font-size: $fontSize4;
            // margin-bottom: $size9;
        }
    }
}

// =================
// === HeroImage ===
// =================

.HeroImage {
    align-self: end;
    max-height: 100%;
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {

}

// For mobile screens
@media (max-width: 480px) {
    .HeroImage {
        display: none;
    }
}

// ================
// === Projects ===
// ================

.Projects {
    padding: $size2 $size8 $size11;
    background-color: $grey200;
    // background-color: $white;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    li { 
        position: relative;
        max-width: 550px;
        margin: $size8 $size3;

        .card-outer-container {
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: $size6;
            cursor: pointer;
            transition: .2s;

            @media (hover:hover) {
                &.hover {
                    box-shadow: 0 0 2pt 2pt $secondary500;
                }
            }

            .card-inner-container {
                border-radius: 5px;
                width: 1100px;
                display: flex;

                .img1-container {
                    width: 550px;
                    height: auto;
                    display: inline-block;
                }

                .img2-container {
                    width: 550px;
                    height: auto;
                    display: inline-block;
                    position: relative;
                }

            }
        }

        .text-container {
            padding: $size5;
            position: absolute;
            top: 0;
            right: 0;
            width: 350px;
            
            p {
                color: $white;
                font-size: $fontSize5;
            }

            // .underline {
            //     border-bottom: 1px solid $white;
            //     opacity: .5;
            //     margin: $size5 0;
            // }

            .tech-text {
                color: $white;
                font-size: $fontSize6;
            }
        }

        .title-link-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                margin-bottom: 0;
                border-bottom: 3px solid $black;
                transition: all $transition;
                cursor: pointer;

                @media (hover:hover) {
                    &.hover {
                        border-color: $secondary500;
                    }
                }
            }
        }
    }
}

// For Hover Devices
@media (hover:hover) {
    .touchscreen-instructions {
        display: none;
    }
}

// For Touch Devices
@media (hover:none), (hover:on-demand) {
    .touchscreen-instructions {
        display: block;
        color: $grey700;
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {

}

// For mobile screens
@media (max-width: 480px) {
    li {
        max-width: 300px;
        margin-bottom: $size6;
        // border-radius: 0px;

        .card-outer-container {
            width: 100vw;
            // border-radius: none;

            .card-inner-container {
                width: 600px;
                // border-radius: none;
    
                .img1-container {
                    width: 100vw;
                }

                .img2-container {
                    width: 100vw;
                }

                img {
                    width: 100vw;
                    // border-radius: none;
                }

                .text-container {
                    padding: $size4;
                    left: 0;
                    width: 100vw;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .text-inner-container {
                        width: 55vw;

                        p {
                            font-size: $fontSize2;
                        }
            
                        .underline {
                            margin: $size3 0;
                        }
            
                        .tech-text {
                            font-size: $fontSize3;
                        }
                    }
                }
            }
        }
    }

    .Projects {
        padding-left: $size5;
        padding-right: $size5;
        padding-bottom: $size8;

        h2 {
            margin-bottom: $size10;
        }

        li {
            margin-top: $size7;

            .card-outer-container {
                border-radius: 0;
            }

            .title-link-container {
                flex-direction: column;
                align-items: flex-start;
                margin: $size5;

                h3 {
                    margin-bottom: $size6;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    .primary-btn {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

// ==============
// === Skills ===
// ==============

.Skills {
    padding: $size2 $size8 $size11;
    // background-color: $secondary100;
    // background-color: $primary900;
    background-color: $black;
    // background-color: $grey800;

    h2, h3, p {
        color: $white;
        // color: $grey200;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
            max-width: $size13;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin: $size7 $size5;
            // background-color: $primary100;
        }
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {

}

// For mobile screens
@media (max-width: 480px) {
    .Skills {
        padding-left: $size5;
        padding-right: $size5;
        padding-bottom: $size8;
    }
}

// ==================
// === Technology ===
// ==================

.Technology {
    padding: $size2 $size8 $size11;
    background-color: $grey200;
    // background-color: $secondary200;

    .techlist-container {
        margin-bottom: $size8;

        .techlist {
            display: flex;
            flex-wrap: wrap;

            li {
                margin: 0 $size5 $size6;;
                position: relative;

                div {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: $size9;
                    height: $size9;
                    border-radius: 5px;
                    background-color: $white;
                    z-index: 2;
                }

                h4 {
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    z-index: 1;
                }
            }
        }
    }
}

// For Touch Devices 
@media (hover:none), (hover:on-demand) {
    .Technology {

        .techlist-container {

            .techlist {
                // justify-content: center;
                
                li {

                    h4 {
                        position: relative;
                        margin-top: $size3;
                    }
                }
            }
        }
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 769px) {
    // .Technology {

    //     .techlist-container {

    //         .techlist {
    //             // justify-content: center;
                
    //             li {

    //                 h4 {
    //                     position: relative;
    //                     margin-top: $size3;
    //                 }
    //             }
    //         }
    //     }
    // }
}

// For mobile screens
@media (max-width: 480px) {
    .Technology {
        padding-left: $size5;
        padding-right: $size5;
        padding-bottom: $size8;

        h2 {
            margin-bottom: $size10;
        }

        .techlist-container {

            .techlist {
                justify-content: center;

                li {
                    margin-left: $size3;
                    margin-right: $size3;
                }
            }
        }
    }
}

// =============
// === About ===
// =============

.About {
    padding: $size2 $size8 $size4;
    background-color: $black;

    h2 {
        color: $white;
    }

    .about-container {
        display: flex;
        justify-content: space-evenly;

        .img-contact-container {
            margin-right: $size10;

            img {
                max-width: 300px;
                height: auto;
                border-radius: 5px;
            }

            li {
                margin-top: $size6;

                a {
                    display: inline-block;
                    color: $white;
                    text-transform: uppercase;
                    font-size: $fontSize6;
                    border-bottom: 3px solid $white;
                    transition: all $transition;

                    &:hover {
                        border-color: $secondary500;
                    }
                }
            }
        }

        .bio-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: $size16;

            p {
                color: $white;
                margin-bottom: $size6;
                font-size: $fontSize5;
                font-weight: 400;
                line-height: 1.7;
            }
        }        
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {
    .About {

        .about-container {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;

            .bio-container {
                margin-bottom: $size9;
            }

            .img-contact-container {
                margin-right: 0;
                
                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

// For mobile screens
@media (max-width: 480px) {
    .About {
        padding-left: $size5;
        padding-right: $size5;
        padding-bottom: $size8;

        h2 {
            margin-bottom: $size10;
        }
    }
}

// ==============
// === Footer ===
// ==============

.Footer {
    padding: $size10 $size6 $size4;
    background-color: $black;
    
    p {
        color: $grey500;
        text-align: center;

        a {
            display: inline-block;
            color: $grey300;
            border-bottom: 2px solid $black;
            transition: all $transition;

            &:hover {
                border-color: $secondary500;
            }
        }
    }
}

// For medium-large screens
@media (max-width: 1024px) {

}

// For tablets
@media (max-width: 768px) {

}

// For mobile screens
@media (max-width: 480px) {
    .Footer {
        
        p, a {
            font-size: $fontSize1;
        }
    }
}